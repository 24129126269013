<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <hp-widget icon="mdi-list-status">
      <template v-slot:title>
        {{ $t('dashboard.widgets.todos.title') }} ({{ visibleItems.length }})
      </template>

      <template v-slot:actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="resize({ type: 'todos' })"
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button small plain icon class="drag-widget" v-on="on">
              <v-icon>mdi-arrow-expand-all</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <hp-button
              small
              plain
              icon
              @click="close({ type: 'todos' })"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </hp-button>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item-group v-if="loading">
          <v-list-item>
            <v-list-item-content>
              <v-progress-circular
                indeterminate
                color="secondary"
              ></v-progress-circular>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="visibleItems && visibleItems.length > 0">
          <template v-for="(item, index) in visibleItems">
            <v-list-item
              :key="`${item.onboardingTaskId} ${item.onboardingUserId}`"
              @click="goToTask(item)"
            >
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-weight: bold"
                    v-html="getStepName(item)"
                  />
                  <v-list-item-subtitle>
                    {{ getUserName(item.onboardingUserId) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        @click="setTaskVisible($event, item, false)"
                      >
                        <v-icon> mdi-eye-off </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('dashboard.widgets.todos.hide') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('dashboard.widgets.todos.noTasks') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </hp-widget>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Todos',
  mixins: [widgetMixin],
  data: () => ({
    items: [],
    loading: true
  }),

  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees',
      employeesLoaded: 'employees/getEmployeesLoaded',
      updated: 'dashboard/getTasksUpdated',
      onboardingSettings: 'organizations/getOnboardingSettings'
    }),

    visibleItems() {
      if (this.loading || !this.items || this.items.length === 0) {
        return [];
      }

      return this.items.filter((x) => x.visible);
    },

    invisibleItems() {
      if (this.loading || !this.items || this.items.length === 0) {
        return [];
      }

      return this.items.filter((x) => !x.visible);
    },

    steps() {
      return this.onboardingSettings.map((x) => x.steps).flat(2);
    }
  },

  watch: {
    async updated(value) {
      if (value) {
        this.items = await this.getTasks();
      }
    },

    employeesLoaded() {
      this.loading = !this.employeesLoaded;
    }
  },

  async mounted() {
    this.items = await this.getTasks();
    this.loading = !this.employeesLoaded;
  },

  methods: {
    ...mapActions({
      getTasks: 'dashboard/getTasks',
      setTaskVisibility: 'dashboard/setTaskVisibility'
    }),

    goToTask(item) {
      this.$router.push({
        name: 'OnboardingDetails',
        params: { id: item.onboardingUserId }
      });
    },

    getStepName(step) {
      const optionalPrefix = step.optional ? ' Optional: ' : '';
      const stepTranslation =
        this.steps.find((x) => x.id === step.onboardingStepId)?.translation ??
        '';

      return optionalPrefix + this.localize(stepTranslation);
    },

    getUserName(employeeId) {
      var employee =
        this.employees?.find((x) => x.userId === employeeId) ??
        this.employees?.find((x) => x.id === employeeId);

      if (!employee) {
        return '';
      }

      return `${employee.firstName} ${employee.lastName}`;
    },

    async setTaskVisible(event, task, isVisible) {
      event.stopPropagation();
      task.visible = isVisible;
      await this.setTaskVisibility(task);
    }
  }
};
</script>
